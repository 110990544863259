import React, { Suspense } from "react";

import ReactDOM from "react-dom";

import "./site.css";

import { ENV_VARS, getEnv } from "./common/env-variables";
import NotSupportedBrowserPage from "./components/not-supported-browser-page";

function isUnsupportedBrowser() {
  const unsupportedBrowsers = ["MSIE", "Trident", "edge"];
  const userAgent = window.navigator.userAgent;

  for (let i = 0; i < unsupportedBrowsers.length; i++) {
    if (userAgent.indexOf(unsupportedBrowsers[i]) >= 0) {
      return true;
    }
  }

  return false;
}

const toggleValue = getEnv(ENV_VARS.MAINTENANCE_ENABLED);
const isMaintenanceEnabled = toggleValue && toggleValue.toLowerCase() === "true";

if (isUnsupportedBrowser()) {
  ReactDOM.render(
    <React.StrictMode>
      <NotSupportedBrowserPage />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  const App = isMaintenanceEnabled
    ? React.lazy(function () {
        return import("./components/maintenance-page");
      })
    : React.lazy(function () {
        return import("./App");
      });

  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

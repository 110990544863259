export const ENV_VARS = {
  MAINTENANCE_ENABLED: "MAINTENANCE_ENABLED",

  ACCOUNT_API_URL: "ACCOUNT_API_URL",
  ADVISER_PORTAL: "ADVISER_PORTAL",
  SIGN_UP: "SIGN_UP",
  OKTA_URL: "OKTA_URL",

  IDENTITY_SERVER_URL: "IDENTITY_SERVER_URL",
  IDENTITY_SERVER_CLIENT_ID: "IDENTITY_SERVER_CLIENT_ID",
};

export const getEnv = (variableName) => {
  const varKey = "REACT_APP_" + variableName;

  const getFromWindow = (key) => (window && window._env_ ? window._env_[key] : null);

  // Do not rewrite like process && process.env because it will lead to 'Process is not defined error'
  const getFromProcess = (key) => (process.env.NODE_ENV ? process.env[key] : null);

  return getFromWindow(varKey) ?? getFromProcess(varKey);
};

export default ENV_VARS;
